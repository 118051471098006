<template>
  <div class="about">
      <h1>Расположение комплекса --- </h1>
      <div class="add_btn" @click="tabItem = 'add'">Добавить условие </div>
  <div class="langs_block" v-if="tabItem == null ">
    <div class="lang_btn" v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" @click="langBlock = item.id">Тема проекта № {{item.id}}</div>

  </div>
  <div class="content_block">
    <form v-if="tabItem == null ">
      <div class="content" v-for="(item, index) in Info" :key="index">
        <div v-if="langBlock == item.id">
          <h3>Проценты </h3>
          <input type="text" v-model="item.percentage">
          <h3>Текст </h3>
          <input type="text" v-model="item.text">

          <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
          <input type="submit" value="Удалить условие" class="btn_form" @click="delet(item.id)">
        </div>
      </div>
    </form>
    <form @submit.prevent="SendAdd" v-if="tabItem == 'add'">
          <h3>Проценты </h3>
          <input type="text" v-model="percentage">
          <h3>Текст </h3>
          <input type="text" v-model="text">

          <input type="submit" value="Отправить" class="btn_form"> 
    </form>
  </div>
</div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'

  export default {
  name: "PurchaseView",
  components: {},
  data() {
    return {
      Info: null,
      langBlock: '1',
      tabItem: null,
      percentage: '',
      text: '',
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('get_location').then((res) => {
        this.Info = res.data
      } )
    },
    delet(id) {
        console.log('id', id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/'+id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        this.$router.go(0);
      },
    
      SendAdd() {
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
           "percentage": this.percentage,
            "text": this.text,
          }
        });
        this.$router.go(0);
      },
      SendForm(item) {
        
          axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: item
        });
        this.$router.go(0);
        } 
      },
      
  }

</script>