<template>
  <div class="about">
      <h1>Планировка квартир </h1>
      <div class="add_btn" @click="tabItem = 'add'">Добавить планировку </div>
      <div class="langs_block" v-if="tab == null">
        <div v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" class="block">
          <div class="lang_btn"  @click="langBlock = item.id" >Планировка № {{index + 1}} {{ item.title }}</div>
          <div class="form">
            <form v-if="tab == null ">
              <div class="content">
                <div v-if="langBlock == item.id"  class="block_item">
                  <h3>number_of_rooms  </h3>
                  <input type="text" v-model="item.number_of_rooms">
                  <h3>square </h3>
                  <input type="text" v-model="item.square">
                  <h3>cost </h3>
                  <input type="text" v-model="item.cost">
                  <div style="width:100%;margin: 16px 0px;">
                    <label for="scales">is_availabl </label>
                    <input type="checkbox" class="checkbox" v-model="is_available">
                  </div>
                  <div class="gallery_items">
                    <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
                      <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.layout_image.id" checked>
                      <label :for="img.id">
                          <img :src="img.image" alt="" :class="{activ: item.layout_image.id == img.id}">
                      </label>
                    </div>
                  </div>
                  
          <!--  -->
                  <input type="submit" value="Сохранить" class="btn_form" @click.prevent="SendForm(item)">
                  <input type="submit" value="Удалить преимущесто" class="btn_form" @click.prevent="delit(item.id)">
                  <!-- <div class="gallery_items">
                    <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
                      <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.image.id" checked>
                      <label :for="img.id">
                          <img :src="img.image" alt="" :class="{activ: item.image.id == img.id}">
                      </label>
                    </div>
                  </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      <!--  -->
  <!-- <div class="langs_block" v-if="tabItem == null ">
    <div class="lang_btn" v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" @click="langBlock = item.id">Планировка № {{item.id}}</div>

  </div>
  <div class="content_block">
    <form v-if="tabItem == null ">
      <div class="content" v-for="(item, index) in Info" :key="index">
        <div v-if="langBlock == item.id">
          <h3>number_of_rooms  {{item.id}}</h3>
          <input type="text" v-model="item.number_of_rooms">
          <h3>square </h3>
          <input type="text" v-model="item.square">
          <h3>cost </h3>
          <input type="text" v-model="item.cost">
          <div style="width:100%;margin: 16px 0px;">
            <label for="scales">is_availabl </label>
            <input type="checkbox" class="checkbox" v-model="item.is_available">
          </div>
          
      

          <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
          <input type="submit" value="Удалить Планировку" class="btn_form" @click="delet(item.id)">

          <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="image" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: image == img.id}">
              </label>
          </div>
        </div>
      </div>
    </form>
    <form @submit.prevent="SendAdd" v-if="tabItem == 'add'"> -->
      <!-- <div class="content" v-for="(item, index) in Info" :key="index"> -->
          <!-- <h3>number_of_rooms  </h3>
          <input type="text" v-model="number_of_rooms">
          <h3>square </h3>
          <input type="text" v-model="square">
          <h3>cost </h3>
          <input type="text" v-model="cost">
          <div style="width:100%;margin: 16px 0px;">
            <label for="scales">is_availabl </label>
            <input type="checkbox" class="checkbox" v-model="is_available">
          </div>
          
      

          <input type="submit" value="Создать" class="btn_form" @click.prevent="SendAdd()">  -->
          <!-- <input type="submit" value="Удалить Планировку" class="btn_form" @click="delet(item.id)"> -->

          <!-- <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="image" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: image == img.id}">
              </label> -->
          <!-- </div>
        </div>
    </form> -->
  </div>
</div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'

  export default {
  name: "PurchaseView",
  components: {},
  data() {
    return {
      Info: null,
      langBlock: '1',
      tabItem: null,
      percentage: '',
      text: '',
      image: '1',
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('apartments/').then((res) => {
        this.Info = res.data
      } )
      $api.get('images/').then((res) => {
          this.InfoGalery = res.data
        } )
    },
    delet(id) {
        console.log('id', id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/apartments/'+id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        this.$router.go(0);
      },
    
      SendAdd() {
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/apartments/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
            number_of_rooms: this.number_of_rooms,
            square: this.square,
            cost: this.cost,
            layout_image: this.image,
            is_available: this.is_available,
          }
        });
        this.$router.go(0);
      },
      SendForm(item) {
        
          axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/apartments/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
            number_of_rooms: item.number_of_rooms,
            square: item.square,
            cost: item.cost,
            is_available: item.is_available,
            layout_image: this.image,
          }
        });
        this.$router.go(0);
        } 
      },
      
  }

</script>
<style scoped>
.checkbox {
  position: inherit;
  opacity: 1;
  width: 16px !important;
  height: 16px !important;
}
.news_block {
    display: block;
}
.news_btn {
    max-width: 100%;
    padding: 20px;
    background: #2d353c;
    color: #fff;
    cursor: pointer;
    text-align: center;
}

.langs_block {
  display: block;
  overflow: hidden;
}
.lang_btn {
  width: 100%;
}
.form .form_input{
  width: 96%;
  height: 32px;
  padding: 5px 15px;
}
.btn_form {
  width: 200px;
  margin: 20px 0;
  color: #fff;
  font-weight: 700;
  background: #2d353c;
  cursor: pointer;
  height: 40px;
}
.gallery_items {
  display: flex;
  flex-wrap: wrap;
}
.gallery_item {
  height: 256px;
}
.block {
  border: solid 1px #2d353c;
  margin: 16px 0;
}
.block_item {
  padding: 16px;
}
</style>