import axios from "axios";

export const API_URL = 'https://jk-megapolis-backend.zoom-app.kz/'

const $api = axios.create({
    baseURL: API_URL
})

 $api.interceptors.request.use((config) => {
    config.headers = {
        Authorization: ('Token ' + localStorage.getItem('token'))
    }    
    // config.auth = {
    //     username: 'admin',
    //     password: 'Z11$7u@a'
    // }
    return config
})

export default $api
