<template>
  <div class="about">
      <h1>Условия приобретения</h1>
      <div class="add_btn" @click="tabItem = 'add'">Добавить условие </div>
      <!-- -->
      <div class="langs_block" v-if="tab == null">
        <div v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" class="block">
          <div class="lang_btn"  @click="langBlock = item.id" >Условия приобретения № {{index + 1}}</div>
          <div class="form">
            <form v-if="tabItem == null ">
              <div class="content">
                <div v-if="langBlock == item.id">
                  <h3>Проценты </h3>
                  <input type="text" v-model="item.percentage" class="form_input">
                  <h3>Текст </h3>
                  <input type="text" v-model="item.text" class="form_input">

                  <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
                  <input type="submit" value="Удалить условие" class="btn_form" @click="delet(item.id)">
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
</div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'

  export default {
  name: "PurchaseView",
  components: {},
  data() {
    return {
      Info: null,
      langBlock: '1',
      tabItem: null,
      percentage: '',
      text: '',
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('purchase_terms/').then((res) => {
        this.Info = res.data
      } )
    },
    delet(id) {
        console.log('id', id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/'+id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        this.$router.go(0);
      },
    
      SendAdd() {
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
           "percentage": this.percentage,
            "text": this.text,
          }
        });
        this.$router.go(0);
      },
      SendForm(item) {
        
          axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: item
        });
        this.$router.go(0);
        } 
      },
      
  }

</script>
<style scoped>
.langs_block {
  display: block;
  overflow: hidden;
}
.lang_btn {
  width: 100%;
}
.form .form_input{
  width: 96%;
  height: 32px;
  padding: 5px 15px;
}
.btn_form {
  width: 200px;
  margin: 20px 0;
  color: #fff;
  font-weight: 700;
  background: #2d353c;
  cursor: pointer;
  height: 40px;
}
.gallery_items {
  display: flex;
  flex-wrap: wrap;
}
.gallery_item {
  height: 256px;
}
.block {
  border: solid 1px #2d353c;
  margin: 16px 0;
}
.block_item {
  padding: 16px;
}
</style>