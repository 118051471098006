<template>
  
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/login">login</router-link>
  </nav> -->
  <component :is="layout">
    {{layout}}
    <router-view/>
  </component>
</template>

<script>
  import DefaultLayout from '@/layouts/default.vue'
  import AuthLayout from '@/layouts/auth.vue'
  export default {
    components: {
      DefaultLayout,
      AuthLayout,
    },
    computed: {
      layout() {
        console.log(this.$route.meta.layout || 'default') + '-layout'
        return (this.$route.meta.layout || 'default') + '-layout'
      },
    },
    mounted() {    
      this.Auth()
    },
    methods: {
      Auth() {
        const auth = localStorage.getItem('token')
        console.log('auth', auth)
        if (auth == null) {
          this.$router.push('/login')
        }
      }
    }
  } 
</script>
<style>
  @import 'assets/css/style.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
