<template>
  <div class="about">
      <h1>Галлерея</h1>
  </div>
  <div class="content_block">
    <form>
          <input type="submit" value="Сохранить" class="btn_form"  @click.prevent="SendForm()">
          {{Info}}
          <!-- {{image}} -->
          <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
            <input type="checkbox" :id="img.id" name="imgSecond" :value="img.id" v-model="image" checked>
            <label :for="img.id">
              <img :src="img.image" alt=""  :class="{activ: image.includes(img.id)}">
            </label>
          </div>
    </form>
  </div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'

  export default {
  name: "GalleryView",
  components: {},
  data() {
    return {
      tabItem: null,
      image: [],
      Info: null,
      langBlock: '1',
      InfoGalery: '',
      images: []
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('gallery/get_gallery/').then((res) => {
        this.image = res.data.images
        this.langBlock =  res.data[0].id
      } )
      $api.get('images/').then((res) => {
        console.log('formData',res)
          this.InfoGalery = res.data
        } )
    },
    SendForm() {
      axios({
        method: 'PUT',
        url: 'https://jk-megapolis-backend.zoom-app.kz/gallery/1/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        data: {
          images: this.image
        }
        
      });
        this.$router.go(0);
    },
  },
};

</script>
<style>
[type=checkbox] { 
position: absolute;
opacity: 0;
width: 0;
height: 0;
}

[type=checkbox] + img {
cursor: pointer;
}
ы
[type=checkbox]:checked + img {
outline: 2px solid #f00;
}
</style>