<template>
  <div class="logo">
    <img class="logo_img" src="../assets/img/logo.png">
  </div>
  <div class="link_block">
    <router-link :to="{ name: 'HomeView'}" class="link_item">Главная</router-link> 
    <router-link :to="{ name: 'AboutView'}" class="link_item">О комплексе</router-link>
    <router-link :to="{ name: 'Advantages'}" class="link_item">Преимущества комплекса </router-link>
    <router-link :to="{ name: 'PurchaseView'}" class="link_item">Условия приобретения </router-link>
    <router-link :to="{ name: 'NewsView'}" class="link_item">Новости </router-link>
    <router-link :to="{ name: 'ConstructionView'}" class="link_item">Ход строительства</router-link>
    <router-link :to="{ name: 'GalleryView'}" class="link_item">Галерея</router-link>
    <router-link :to="{ name: 'ApplicationsView'}" class="link_item">Заявки на звонок</router-link>
    <router-link :to="{ name: 'ApartmentView'}" class="link_item">Планировка квартир </router-link>
    <!-- <router-link :to="{ name: 'LocationView'}" class="link_item">Расположение комплекса ---</router-link> -->
    <router-link :to="{ name: 'ImageView'}" class="link_item">Изображения</router-link>
    <router-link :to="{ name: 'auth'}" class="link_item" @click="logout">Выйти</router-link>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      window.location.href = '/login';
    }
  },
}

</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
