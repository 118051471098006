<template>
  <div class="about">
    
    <div class="content_block">
      <form @submit.prevent="save()" v-if="!delite">
        <input @change="getFile" type="file">
        <input type="submit" value="Отправить" class="btn_form">
        
      </form>
      <button class="btn_form" @click="delite=true" v-if="!delite">Удалить картинку</button>
      <button class="btn_form" @click="delite=false" v-if="delite">Назад</button>
      <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
            <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="imgSecond" checked>
            <label :for="img.id">
                <img :src="img.image" alt="" :class="{activ: imgSecond ==img.id}" @click="del(img.id)" v-if="delite">
                <img :src="img.image" alt="" :class="{activ: imgSecond ==img.id}" v-else>
            </label>
          </div>
    </div>
  </div>
</template>


<script>
  import $api from '../plugins/Api'
  import axios from 'axios'
  
    export default {
    name: "ImageView",
    components: {},
    data() {
      return {
        Info: null,
        delite: false,
        file: null,
        imagesGallery: ['1','2'],
        imageSecond: null,
        InfoGalery: null,
       

      }
    },
  
    mounted() {    
      this.GetApi()
    },

    methods: {
      del(id) {
        console.log(id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/images/'+id,
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        this.$router.go(0);
      },
      img() {

      },
      GetApi() {
        // $api.get('main_infos/').then((res) => {
        //   this.Info = res.data[0]
        // } )
        $api.get('images/').then((res) => {
          this.InfoGalery = res.data
        } )
      },
      getFile(e) {
        this.file = e.target.files || e.dataTransfer.files;
        console.log('file', this.file)
        
      },
      save() {
      console.log(this.file[0]);
        let formData = new FormData();
        formData.append("image", this.file[0]);
        console.log('formData',formData)
        console.log('this.file',this.file[0])
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/images/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: formData
        });
        // this.$router.go(0);
      },
      
    },
  };
  
  </script>
  <style>
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type=radio] + img {
  cursor: pointer;
}

[type=radio]:checked + img {
  outline: 2px solid #f00;
}
  </style>