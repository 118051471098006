<template>
  <div class="about">
      <h1>Заявки на звонок </h1>

    <div class="calls">
      <div class="calls-item" v-for="(item, index) in Info" :key="index" >
        <div>Заявка № {{item.id}} </div>
        <div>
          <div class="call-name"> {{ item.name}}</div>
          <div class="call-tel">  {{item.phone_number}}</div>

        </div>
        <div class="calls-btn" @click="delet(item.id)">Удалить</div> 
        <div class="call-info" v-if="tab == item.id">
        
        </div>
      </div>

    </div>


  <!-- <div class="langs_block" v-if="tabItem == null ">
    <div class="lang_btn"  :class="{active: langBlock == 'en'}" @click="langBlock = item.id">Тема проекта № {{item.id}}</div>

  </div> -->
  <div class="content_block">
    <!-- <form v-if="tabItem == null ">
      <div class="content" v-for="(item, index) in Info" :key="index">
        <div v-if="langBlock == item.id">
          <h3>Проценты </h3>
          <input type="text" v-model="item.percentage">
          <h3>Текст </h3>
          <input type="text" v-model="item.text">

          <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
          <input type="submit" value="Удалить условие" class="btn_form" @click="delet(item.id)">
        </div>
      </div>
    </form>
    <form @submit.prevent="SendAdd" v-if="tabItem == 'add'">
          <h3>Проценты </h3>
          <input type="text" v-model="percentage">
          <h3>Текст </h3>
          <input type="text" v-model="text">

          <input type="submit" value="Отправить" class="btn_form"> 
    </form> -->
  </div>
</div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'

  export default {
  name: "ApplicationsView",
  components: {},
  data() {
    return {
      Info: null,
      tab: null,
      tabItem: null,
      percentage: '',
      text: '',
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('call_requests/').then((res) => {
        this.Info = res.data
      } )
    },
    delet(id) {
        console.log('id', id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/call_requests/'+id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        // this.$router.go(0);
      },
    
      SendAdd() {
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/project_titles/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
           "percentage": this.percentage,
            "text": this.text,
          }
        });
        // this.$router.go(0);
      },
      SendForm(item) {
        
          axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/purchase_terms/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: item
        });
        // this.$router.go(0);
        } 
      },
      
  }

</script>
