<template>
    <div class="about">
      <h1>Преимущества комплекса</h1>
      <div class="add_btn" @click="this.tab='add'">Добавить преимущесто </div>
    <div class="langs_block" v-if="tab == null">
        <div v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" class="block">
          <div class="lang_btn"  @click="langBlock = item.id" >Преимущесто № {{index + 1}} {{ item.title }}</div>
          <div class="form">
            <form v-if="tab == null ">
              <div class="content">
                <div v-if="langBlock == item.id"  class="block_item">
                  <h3>Название  {{item.id}}</h3>
                  <input class="form_input" type="text" v-model="item.title">
                  <h3>Текст {{item.id}}</h3>
                  <!-- <input type="text" class="form_input" v-model="item.text"> -->
                  <textarea class="form_textarea" v-model="item.text" rows="10" cols="45" name="text"></textarea>
                  <input type="submit" value="Сохранить" class="btn_form" @click.prevent="SendForm(item)">
                  <input type="submit" value="Удалить преимущесто" class="btn_form" @click.prevent="delit(item.id)">
                  <div class="gallery_items">
                    <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
                      <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.image.id" checked>
                      <label :for="img.id">
                          <img :src="img.image" alt="" :class="{activ: item.image.id == img.id}">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
     <div class="content_block"  >
    <!--  <form v-if="tab == null ">
        <div class="content" v-for="(item, index) in Info" :key="index">
          <div v-if="langBlock == item.id">
            <h3>Название  {{item.id}}</h3>
            <input type="text" v-model="item.title">
            <h3>Текст {{item.id}}</h3>
            <input type="text" v-model="item.text">
            <input type="submit" value="Сохранить" class="btn_form" @click.prevent="SendForm(item)">
            <input type="submit" value="Удалить преимущесто" class="btn_form" @click.prevent="delit(item.id)">
            <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.image.id" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: item.image.id == img.id}">
              </label>
            </div>
          </div>
        </div>
      </form> -->

      <!-- <form @submit.prevent="SendAdd" v-if="tab == 'add'">
            <h3>Название</h3>
            <input type="text" v-model="title">
            <h3>Текст</h3>
            <input type="text" v-model="text">
            <input type="submit" value="Отправить" class="btn_form">
            <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="image" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: image == img.id}">
              </label>
            </div>
      </form> -->
      
    </div>
  </div>
</template>
<script>
  import $api from '../plugins/Api'
  import axios from 'axios'
  
    export default {
    name: "ObjectiveView",
    components: {},
    data() {
      return {
        Info: null,
        langBlock: '1',
        // tab: 'add',
        tab: null,
        InfoGalery: [],
        image: '',
      }
    },
  
    mounted() {    
      this.GetApi()
    },

    methods: {
      

      GetApi() {
        $api.get('advantages/').then((res) => {
          this.Info = res.data

        } )
        $api.get('images/').then((res) => {
          this.InfoGalery = res.data
        } )
      },
      SendForm(item) {
        console.log('item',item)
        console.log('title',item.title,)
        console.log('text',item.text,)
        console.log('image',this.image)
        axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/advantages/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
            "id": item.id,
            "title": item.title,
            "text": item.text,
            "image": item.image.id,
          }
          
        });
        this.$router.go(0);
      },
      SendAdd() {
      
      axios({
        method: 'POST',
        url: 'https://jk-megapolis-backend.zoom-app.kz/advantages/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        data: {
          title: this.title,
          text: this.text,
          image: this.image,
        },
        
        });
        this.$router.go(0);
      },
      delit(id) {
        console.log('id',id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/advantages/'+id,
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        this.$router.go(0);
      },
    },
  };
  
  </script>
  <style scoped>
  .langs_block {
    display: block;
    overflow: hidden;
  }
  .lang_btn {
    width: 100%;
  }
  .form .form_input {
    width: 96%;
    height: 32px;
    padding: 5px 15px;
  }
  .form .form_textarea {
    width: 96%;
    padding: 5px 15px;
  }
  .btn_form {
    width: 200px;
    margin: 20px 0;
    color: #fff;
    font-weight: 700;
    background: #2d353c;
    cursor: pointer;
    height: 40px;
  }
  .gallery_items {
    display: flex;
    flex-wrap: wrap;
  }
  .gallery_item {
    height: 256px;
  }
  .block {
    border: solid 1px #2d353c;
    margin: 16px 0;
  }
  .block_item {
    padding: 16px;
  }
  </style>