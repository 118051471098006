<template>
    <div class="home_layouts">
        <div class="sidebar">
            <Sidebar />
        </div>
        <div class="home_layouts-content">
            <router-view />
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/sidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Sidebar
  }
}
</script>
