<template>
  <div class="about">
      <h1>Ход строительства</h1>
      <div class="add_btn" @click="tabItem = 'add'">Добавить Ход строительства </div>

      <div class="langs_block" v-if="tab == null">
        <div v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" class="block">
          <div class="lang_btn"  @click="langBlock = item.id" >Ход строительства № {{index + 1}} {{ item.title }}</div>
          <div class="form">
            <form v-if="tabItem == null ">
              <div class="content">
                <div v-if="langBlock == item.id">
                  <h3>Дата {{index + 1 }}</h3>
                  <input type="text" v-model="item.us_date" class="form_input">
                  <h3>Текст {{index + 1 }}</h3>
                  <input type="text" v-model="item.text" class="form_input">

                  <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
                  <input type="submit" value="Удалить новость" class="btn_form" @click="delet(item.id)">
                  <div class="gallery_items">
                    <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
                        <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.image.id" checked>
                        <label :for="img.id">
                            <img :src="img.image" alt="" :class="{activ: item.image.id == img.id}">
                        </label>
                      </div>
                    </div>
                </div>
              </div> 
            </form>
          </div>
        </div>
    </div>
  <!-- <div class="langs_block" v-if="tabItem == null ">
    <div class="lang_btn" v-for="(item, index) in Info" :key="index" :class="{active: langBlock == 'en'}" @click="langBlock = item.id">Ход № {{item.id}}</div>

  </div>
  <div class="content_block">
    <form v-if="tabItem == null ">
      <div class="content" v-for="(item, index) in Info" :key="index">
        <div v-if="langBlock == item.id">
          <h3>Дата {{index}}</h3>
          <input type="text" v-model="item.us_date">
          <h3>Текст {{index}}</h3>
          <input type="text" v-model="item.text">

          <input type="submit" value="Отправить" class="btn_form" @click.prevent="SendForm(item)"> 
          <input type="submit" value="Удалить Ход" class="btn_form" @click="delet(item.id)">

          <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="item.image.id" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: item.image.id == img.id}">
              </label>
            </div>
        </div>
      </div> 
    </form>
    <form @submit.prevent="SendAdd" v-if="tabItem == 'add'">
          <h3>Дата </h3>
          <input type="text" v-model="us_date">
          <h3>Текст </h3>
          <input type="text" v-model="text">

          <input type="submit" value="Отправить" class="btn_form"> 
          <div class="gallery_item" v-for="img in InfoGalery" :key="img.id">
              <input type="radio" :id="img.id" name="imgSecond" :value="img.id" v-model="image" checked>
              <label :for="img.id">
                  <img :src="img.image" alt="" :class="{activ: image == img.id}">
              </label>
            </div>
    </form> -->
    
  <!-- </div> -->
</div>
</template>
<script>
import $api from '../plugins/Api'
import axios from 'axios'
  // import Editor from '@tinymce/tinymce-vue'

  export default {
  name: "ConstructionView",
  components: {
    // 'editor': Editor
  },
  data() {
    return {
      tabItem: null,
      Info: null,
      langBlock: '1',
      image: '',
      imagesGallery: '',
    }
  },

  mounted() {    
    this.GetApi()
  },

  methods: {
    GetApi() {
      $api.get('construction_progress/').then((res) => {
        this.Info = res.data
      } )
        $api.get('images/').then((res) => {
          this.InfoGalery = res.data
        } )
    },
    delet(id) {
        console.log('id', id)
        axios({
          method: 'delete',
          url: 'https://jk-megapolis-backend.zoom-app.kz/construction_progress/'+id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
        }).then((res) => {
          console.log('res',res)
        }).catch((err) => {
          console.log('err',err)
        })
        // this.$router.go(0);
      },
    
      SendAdd() {
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/construction_progress/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
            us_date: this.us_date,
            text: this.text,
            image: this.image,
          }
        });
        // this.$router.go(0);
      },
      SendForm(item) {
        
          axios({
          method: 'PUT',
          url: 'https://jk-megapolis-backend.zoom-app.kz/construction_progress/'+item.id+'/',
          headers: { 
            'Authorization': ('Token ' + localStorage.getItem('token'))
          },
          data: {
            us_date: item.us_date,
            text: item.text,
            image: item.image.id,
          }
        });
        // this.$router.go(0);
        } 
      },
};

</script>
<style scoped>

.langs_block {
  display: block;
  overflow: hidden;
}
.lang_btn {
  width: 100%;
}
.form .form_input{
  width: 96%;
  height: 32px;
  padding: 5px 15px;
}
.btn_form {
  width: 200px;
  margin: 20px 0;
  color: #fff;
  font-weight: 700;
  background: #2d353c;
  cursor: pointer;
  height: 40px;
}
.gallery_items {
  display: flex;
  flex-wrap: wrap;
}
.gallery_item {
  height: 256px;
}
.block {
  border: solid 1px #2d353c;
  margin: 16px 0;
}
.block_item {
  padding: 16px;
}
</style>