import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from '../views/AuthView.vue'
import GalleryView from '../views/GalleryView.vue'
import NewsView from '../views/NewsView.vue'
import Advantages from '../views/Advantages.vue'
import PurchaseView from '../views/PurchaseView.vue'
import ConstructionView from '../views/ConstructionView.vue'
import ImageView from '../views/ImageView.vue'
import ApplicationsView from '../views/ApplicationsView.vue'
import ApartmentView from '../views/ApartmentView.vue'
import LocationView from '../views/LocationView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    meta: {layout: 'default'},
    component: HomeView
  },
  {
    path: '/applications',
    name: 'ApplicationsView',
    meta: {layout: 'default'},
    component: ApplicationsView
  },
  {
    path: '/apartment',
    name: 'ApartmentView',
    meta: {layout: 'default'},
    component: ApartmentView
  },
  {
    path: '/location',
    name: 'LocationView',
    meta: {layout: 'default'},
    component: LocationView
  },
  {
    path: '/gallery',
    name: 'GalleryView',
    meta: {layout: 'default'},
    component: GalleryView
  },
  {
    path: '/news',
    name: 'NewsView',
    meta: {layout: 'default'},
    component: NewsView
  },
  {
    path: '/advantages',
    name: 'Advantages',
    meta: {layout: 'default'},
    component: Advantages
  },
  {
    path: '/purchase',
    name: 'PurchaseView',
    meta: {layout: 'default'},
    component: PurchaseView
  },
  {
    path: '/construction',
    name: 'ConstructionView',
    meta: {layout: 'default'},
    component: ConstructionView
  },
  // 
  {
    path: '/login',
    name: 'auth',
    meta: {layout: 'auth'},
    component: AuthView
  },
  {
    path: '/image',
    name: 'ImageView',
    meta: {layout: 'default'},
    component: ImageView
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
