<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/img/logo.png">
    <!-- <HelloWorld msg="Добро пожаловать в панель администратора"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script>
