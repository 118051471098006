<template>
  <div class="about login">
    <h1>Вход</h1>
    <form action="" @submit.prevent="LoginForm">
      <input v-model="user" placeholder="Логин">
      <input v-model="password" placeholder="Пароль">
      <input type="submit" class="submit" value="Отправить">
    </form>
    <span style="color: red" v-if="err">Неверные данные</span>
  </div>
</template>

<script>
  import axios from 'axios'
    export default {
    name: "AuthView",
    data() {
      return {
        user: '',
        password: '',
        err: false,
      }
    },


    methods: {
      LoginForm() {
        console.log('Логин',this.user)
        console.log('Пароль',this.password)
       const data = {
            username: this.user,
            password: this.password,
          }
          console.log('data',data)
        axios({
          method: 'POST',
          url: 'https://jk-megapolis-backend.zoom-app.kz/get_token',
          headers: { 
            'Authorization': 'Token ca454e42cdcfaf5e998256c332a82cd4d653d52a', 
          },
          data: data
        }
        ).then(res => {
          this.token = res.data.result
          localStorage.setItem('token', this.token)
          window.location.href = '/';
          // this.router.push({ name: 'HomeView' })
        })
  .catch(error => {
    console.log(error)
    this.err = true
  });
      },
    },
    
  };
  
</script>
